import * as React from "react";
import "./Footer.sass";
import logo_white from "../images/logo_white.png";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer>
      <div id="footer-row-1">
        <div id="footer-col-1">
          <img src={logo_white} alt="weely logo"></img>
        </div>
        <div id="footer-col-2">
          <ul>
            <li className="title">Produit</li>
            <li>
              <Link to="/">Fonctionalités</Link>
            </li>
            <li>
              <Link to="/price">Prix</Link>
            </li>
            <li>
              <Link to="/contact">Inscription</Link>
            </li>
          </ul>
          <ul>
            <li className="title">À propos</li>
            <li>
              <Link to="https://www.instagram.com/weely.ch/" target="_blank">
                Instagram
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/weely.ch" target="_blank">
                Facebook
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/company/weely/"
                target="_blank"
              >
                Linkedin
              </Link>
            </li>
          </ul>
          <ul>
            <li className="title">Aide</li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <div id="footer-row-2">
        <p>2021 weely.ch. Tous droits réservés</p>
      </div>
    </footer>
  );
};

export default Footer;
