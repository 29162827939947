import * as React from "react";
import "./Nav.sass";
import logo from "../images/logo.png";
import { Link } from "gatsby";
import { useState } from "react";

const Nav = () => {
  const [open, setOpen] = useState(false);
  return (
    <nav>
      <Link to="/">
        <img src={logo} alt="weely logo"></img>
      </Link>
      <ul className={open ? "open" : ""}>
        <li>
          <Link to="/" activeClassName="active">
            Accueil
          </Link>
        </li>
        <li>
          <Link to="/solution" activeClassName="active">
            Solution
          </Link>
        </li>
        <li>
          <Link to="/price" activeClassName="active">
            Offres
          </Link>
        </li>
        <li>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/price" id="cta">
            S'inscrire
          </Link>
        </li>
      </ul>
      <div
        id="nav-button"
        className={open ? "open" : "closed"}
        onClick={() => {
          setOpen(!open);
        }}
        onKeyDown={() => {
          setOpen(!open);
        }}
        role="button"
        tabIndex={0}
        aria-label="Open navigation"
      ></div>
    </nav>
  );
};

export default Nav;
