import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Spiel - Bedingungen und Konditionen</h2>
        <p>
          Artikel I: Organisation und Kontext Webpanda (im Folgenden als
          "Veranstalter" oder "Organisator" bezeichnet) organisiert ein
          kostenloses Spiel (im Folgenden als "Spiel" bezeichnet). Der
          Veranstalter organisiert dieses Spiel im Namen von Partner-Restaurants
          und -Geschäften mit dem Ziel, die Besucher (im Folgenden einzeln als
          "Spieler" und gemeinsam als "Spieler" bezeichnet) zu belohnen, die
          einen der am Spiel teilnehmenden Partnerbetriebe von Weely (Bar,
          Restaurant, Konzertsaal, Kino, Schönheitssalon, Wellness-Center usw.)
          besuchen. Es wird ausdrücklich darauf hingewiesen, dass sich die Rolle
          des Veranstalters auf die Organisation des Spiels und die Übergabe der
          gewonnenen Preise beschränkt. Da Webpanda keine Institution ist, kann
          das Unternehmen in dieser Hinsicht nicht haftbar gemacht werden. Das
          Spiel findet ausschließlich auf der Website von Weely statt. <br />{" "}
          <br />
          Artikel II: Teilnahmebedingungen Die Teilnahme am Spiel ist den
          Spielern der Weely-Partnerbetriebe vorbehalten. Die Teilnahme ist an
          die Bedingung geknüpft, dass der Spieler in dem Partnerbetrieb Geld
          ausgibt. Weely und seine Partnerbetriebe können von den Spielern einen
          Zahlungsnachweis (Rechnung, Quittung, etc.) verlangen. Jeder Spieler,
          der am Spiel teilnehmen möchte, muss: - den Betrieb auswählen, in dem
          er spielen möchte; - seine E-Mail-Adresse angeben; - eine der
          geforderten Aktionen durchführen (dem Betrieb auf Instagram oder
          Facebook folgen, einen Kommentar auf Google hinterlassen oder eine
          Frage zur Zufriedenheit mit dem Besuch beantworten; - das virtuelle
          Rad drehen, das auf der Website oder der App zur Verfügung steht. Nach
          der Teilnahme am Spiel abonniert der Spieler über Weely Nachrichten
          und exklusive Angebote des Unternehmens. Wenn er es wünscht, kann er
          dies bei der Registrierung für das Spiel oder danach ablehnen. <br />{" "}
          <br />
          Artikel III : Preis Je nach Einrichtung kann der Preis des Spiels aus
          einem Geschenk oder einem Preis bestehen, der von der Einrichtung
          angeboten wird. Dieser Preis wird beim nächsten Besuch des Spielers in
          der Einrichtung zur Verfügung stehen. Geschenk - Wenn es sich um einen
          Preis handelt, der von der Einrichtung angeboten wird, werden die Art
          des Preises sowie der verfügbare Vorrat speziell von der Einrichtung
          festgelegt. Es kann sich zum Beispiel um ein Getränk handeln, das im
          Rahmen eines nächsten Besuchs angeboten wird, oder um eine Strategie 1
          gekauft/1 angeboten. <br /> <br />
          Artikel IV: Benennung der Gewinner Die Ziehungen erfolgen automatisch
          nach der Teilnahme dank eines Zufallsalgorithmus. Sobald das virtuelle
          Rad gedreht ist, erfährt der Spieler seinen Preis. Die Gewinner
          erhalten eine Bestätigungs-E-Mail, die sie dem Personal der
          Einrichtung vorlegen müssen, um ihren Gewinn bei ihrem nächsten Besuch
          einzufordern. - Sollte sich herausstellen, dass ein Teilnehmer durch
          Nichteinhaltung der vorliegenden Regeln, durch betrügerische Mittel
          oder durch andere Mittel als die, die sich aus dem von Weely
          beschriebenen Verfahren ergeben, oder durch Verletzung einer der
          Teilnahmebedingungen gewonnen hat, verliert er/sie seine/ihre
          Eigenschaft als Gewinner, unbeschadet möglicher rechtlicher Schritte,
          die von der veranstaltenden Gesellschaft gegen den Teilnehmer
          eingeleitet werden könnten. In diesem Fall erhält der Teilnehmer eine
          E-Mail, in der er über den Verlust seiner Gewinnereigenschaft
          informiert und an die vorliegenden Regeln erinnert wird. <br /> <br />
          Artikel V: Bedingungen für die Teilnahme Die Anmeldung zum Spiel setzt
          die bedingungslose Annahme und Einhaltung der vorliegenden Spielregeln
          voraus, die während des Spiels jederzeit auf der Weely-Website
          abrufbar sind und über die das Organisationskomitee in allen
          strittigen Fällen und bei Auslegungsschwierigkeiten das letzte Wort
          hat. Im Falle der Ablehnung aller oder eines Teils dieser Regeln
          obliegt es den Spielern, von der Teilnahme am Spiel Abstand zu nehmen
          - die Teilnahme ist streng nominativ und der Spieler darf unter keinen
          Umständen unter mehreren Pseudonymen oder im Namen anderer Mitglieder
          spielen. - Es wird davon ausgegangen, dass ein Teilnehmer als eine
          einzige physische Person definiert ist: Die Verwendung verschiedener
          Mitgliedskonten durch ein und denselben Teilnehmer wird als
          Betrugsversuch gewertet und führt zum endgültigen Ausschluss des
          Teilnehmers. - Es ist strengstens untersagt, dass ein Teilnehmer von
          einer E-Mail aus spielt, die für eine andere Person geöffnet wurde.
          Für jede teilnehmende natürliche Person darf nur eine einzige E-Mail
          verwendet werden. - Es ist strengstens untersagt, die vorgeschlagenen
          Spielvorrichtungen auf irgendeine Weise zu verändern oder zu
          versuchen, sie zu verändern, insbesondere um die Ergebnisse zu
          verändern oder die Gültigkeit der Auslosung durch automatische oder
          unfaire Mittel zu beeinflussen. - Um der Gefahr von Betrug
          vorzubeugen, darf jeder Teilnehmer pro Einrichtung nur einmal am
          selben Tag spielen. - Jede Teilnahme ist mit einer einzigen Rechnung
          verknüpft. Der Veranstalter ist befugt, alle notwendigen Überprüfungen
          vorzunehmen, um die Abwesenheit von Betrug festzustellen. <br />{" "}
          <br />
          Artikel VI: Verleihung der Preise Die Bedingungen für die Auszahlung
          des Preises werden in einer E-Mail bekannt gegeben, sobald ein
          Teilnehmer den Status eines Gewinners erlangt hat. - Im Falle von
          Preisen, die von der Anstalt zur Verfügung gestellt werden, obliegt es
          dem Personal der Anstalt, die Einhaltung der Teilnahmebedingungen zu
          überprüfen und den per E-Mail erhaltenen Preis zu scannen. Für den
          Fall, dass sich die Kontaktdaten des Gewinners ganz oder teilweise als
          offensichtlich falsch oder fehlerhaft erweisen, insbesondere seine
          E-Mail-Adresse oder seine Telefonnummer, ist es in keinem Fall Aufgabe
          des Veranstalters, Nachforschungen jeglicher Art anzustellen, um den
          Gewinner ausfindig zu machen. Der Gewinner verliert den Anspruch auf
          seinen Preis und hat keinen Anspruch auf eine Entschädigung. <br />{" "}
          <br />
          Artikel VII: Anwendbares Recht und Gerichtsbarkeit Die Teilnahme an
          diesem Spiel setzt die uneingeschränkte Annahme aller Bestimmungen der
          vorliegenden Regeln voraus. Im Falle einer Anfechtung ist nur eine
          E-Mail an contact@weely.ch innerhalb von höchstens 30 (dreißig) Tagen
          nach dem Datum der Teilnahme zulässig. Mit Ausnahme von
          offensichtlichen Irrtümern wird vereinbart, dass die von den
          Computersystemen des Veranstalters stammenden Informationen bei
          Streitigkeiten über die Anschlussbedingungen, die Einreichung von
          Beiträgen und die computergestützte Verarbeitung dieser Informationen
          im Zusammenhang mit dem Spiel Beweiskraft haben. Die Teilnehmer
          unterliegen den schweizerischen Vorschriften für Spiele und
          Wettbewerbe. Alle Streitigkeiten, die nicht gütlich beigelegt werden
          können, werden den zuständigen Gerichten am Sitz des Veranstalters
          vorgelegt, sofern die öffentliche Ordnung nichts anderes vorsieht.
          Sollte eine der Klauseln des vorliegenden Reglements für nichtig
          erklärt werden, so berührt dies in keiner Weise die Gültigkeit des
          Reglements selbst, und alle anderen Klauseln behalten ihre Gültigkeit
          und ihren Geltungsbereich. Für alle Fragen bezüglich der
          Funktionsweise des Spiels: contact@weely.ch
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
