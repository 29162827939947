import * as React from "react";
import "@fontsource/dm-sans";
import "./Layout.sass";
import "../Styles.sass";
import "../patterns.css";
import Nav from "./Nav";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div id="layout">
      <Nav></Nav>
      {children}
      <Footer></Footer>
    </div>
  );
};

export default Layout;
